import React from 'react'
import {Link} from 'gatsby'
import BlogCard from "../BlogContent/BlogCard"

const OurBlog = ({lastPosts}) => {
    return (
      <section className="blog-area pt-100 pb-70 bg-f1f8fb">
          <div className="container">
              <div className="section-title">
                  <span className="sub-title">Aktuelles</span>
                  <h2>News und Blog</h2>
                <p>Schauen Sie doch auch in unserem <Link to={"/lexikon/"}>Lexikon</Link> vorbei</p>
              </div>

              <div>
                  {lastPosts.map(({node: post}) => (
                    <BlogCard key={post.id}   post={post}/>
                    ))}
              </div>

              <Link to="/blog/" className="btn btn-primary" style={{ textDecoration: "none" }}>Alle Artikel</Link>
          </div>
      </section>
    );
}

export default OurBlog
