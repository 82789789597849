import React from "react"
import PopularLexikonEntriesWidget from "./widgets/PopularLexikonEntriesWidget"

const LexikonSidebar = ({ withSearch = false }) => {
  return (
    <PopularLexikonEntriesWidget/>
  )
}

export default LexikonSidebar
