import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const NetstorsysHero = () => {
  return (
    <section className="overview-area ptb-100 pt-0 bg-f1f8fb">
      <div className="container">
        {/* Left Image Style */}
        <div className="overview-box">
          <div className="overview-image">
            <div className="image">
              <StaticImage
                src="../../assets/images/products/netversys/devices/nv_dashboard_devices_all.png"
                alt="Netversys auf verschiedenen Geräten"
                placeholder="blurred"
                width={900}
              />
            </div>
          </div>

          <div className="overview-content">
            <div className="content right-content mt-5">
              <span className="sub-title">NETVERSYS - Die Software für Versandlogistik</span>
              <h2>Optimieren Sie Ihren Versand</h2>
              <p>Mit unserer Software für eine optimierte Versandlogistik
              bringen Sie Ihre Waren schneller an den Kunden und behalten dabei
              die Kosten für Versanddienstleister immer im Blick. Profitieren Sie
              von kundenorientierten Vorteilen wie:</p>

              <ul className="features-list">
                <li><span><i className="bx bx-check" /> Carrier Entgeltmanagement</span></li>
                <li><span><i className="bx bx-check" /> perfekte Versandemails</span></li>
                <li><span><i className="bx bx-check" /> Non-EU Zollmanagement</span></li>
                <li><span><i className="bx bx-check" /> Gefahrguthandling</span></li>
                <li><span><i className="bx bx-check" /> modernes Pluginsystem</span></li>
                <li><span><i className="bx bx-check" /> weltklasse Support</span></li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Left Image Style */}
      </div>
    </section>
  )
}

export default NetstorsysHero
