import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import PopularLexikonEntry from "../PopularLexikonEntry"

const PopularLexikonEntries = ({ numberOfPosts = 4 }) => {
  const data = useStaticQuery(
    graphql`
      query popularLexikonEntriesQuery {
          allPageViews {
            nodes {
              totalCount
              id
            }
          }
          allMarkdownRemark (
            filter: { fields: { collection: { eq: "qa" }}}
          ) {
            nodes {
              id
              fields {
                slug
              }
              frontmatter {
                question
                date(formatString: "DD.MM.YYYY")
              }
            }
          }
        }`
  )

  const { allMarkdownRemark: { nodes: lexikonEntries } } = data
  const { allPageViews: { nodes: pageViews } } = data

  const lexikonEntriesWithHitDataFromGA = lexikonEntries.map((post) => (
    { ...post, pageView: pageViews.find(pageView => pageView.id === post.fields.slug) || { totalCount: 0 } }
  ))
  lexikonEntriesWithHitDataFromGA.sort((a, b) => {
    return b.pageView.totalCount - a.pageView.totalCount
  }).splice(numberOfPosts)

  return (
    <div className="services-details-info">

      <div className="services-contact-info">
        <h3 style={{ borderBottom: "2px solid #F67803" }} className={"pb-2"}>Populäre Einträge</h3>

        <ul>
          {lexikonEntriesWithHitDataFromGA.map((entry) => (
            <PopularLexikonEntry key={entry.id} post={entry} />
          ))}
        </ul>
      </div>
    </div>
  )
}

export default PopularLexikonEntries
