import React from "react"
import { Link } from "gatsby"

const PopularLexikonEntry = ({ post }) => {
  return (
    <li>
      <div className="icon" style={{ top: "0" }}>
        <i className="bx bx-bullseye" />
      </div>
      <span className={"pt-1"} style={{ fontSize: "16px" }}><Link
        to={post.fields.slug}>{post.frontmatter.question}</Link></span>
    </li>
  )
}

export default PopularLexikonEntry
