import React from "react"
import { StaticImage } from "gatsby-plugin-image"

const NetstorsysHero = () => {
  return (
    <section className="overview-area ptb-100 pt-0 bg-f1f8fb">
      <div className="container">
        {/* Left Image Style */}
        <div className="overview-box">
          <div className="overview-image">
            <div className="image">
              <StaticImage
                src="../../assets/images/products/netstorsys/devices/ns_dashboard_devices_all.png"
                alt="Netstorsys auf verschiedenen Geräten"
                placeholder="blurred"
                width={900}
              />
            </div>
          </div>

          <div className="overview-content">
            <div className="content right-content mt-5">
              <span className="sub-title">NETSTORSYS - Die Software für Lagerverwaltung</span>
              <h2>Optimieren Sie Ihr Lager</h2>
              <p>Mit unserer Software für das Supply Chain Management verschaffen
              wir Ihnen den entscheidenden Wettbewerbsvorteil. Egal ob Sie als
              Kontraktlogistiker Logistikdienstleistungen verkaufen oder Ihre
              Waren in eigenen Lagern managen. NETSTORSYS bietet Ihnen
              Vorteile wie:</p>

              <ul className="features-list">
                <li><span><i className="bx bx-check" /> smarte Kommissionierung</span></li>
                <li><span><i className="bx bx-check" /> verschiedene Inventuren</span></li>
                <li><span><i className="bx bx-check" /> Retouremanagement</span></li>
                <li><span><i className="bx bx-check" /> flexible Schnittstellen</span></li>
                <li><span><i className="bx bx-check" /> modernes Pluginsystem</span></li>
                <li><span><i className="bx bx-check" /> weltklasse Support</span></li>
              </ul>
            </div>
          </div>
        </div>
        {/* End Left Image Style */}
      </div>
    </section>
  )
}

export default NetstorsysHero
